


const Guides = () => {

        return (
            <>
                <div className=" w-full mx-auto mt-12 bg-white">
                    <div className=" flex flex-col break-words w-full mb-1 rounded">
                        <div className="w-full">

                            <section class="text-gray-600 w-full overflow-hidden mt-12">
                                <div className="flex flex-col items-center gap-y-3 mb-10 ">
                                    <p className="text-4xl font-semibold text-black">How to Scrape Etsy Reviews Data for Free</p>
                                    <p>A complete guideline about how to scrape Etsy Reviews Data effortlessly without wasting your precious time.</p>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </>
        )

    }
export default Guides;