import ChromeSvg from "@/lib/chromeSvg";

import banner1 from '@/lib/home-banner-1.png'
import banner2 from '@/lib/home-banner-2.png'
import banner3 from '@/lib/home-banner-3.png'
import banner4 from '@/lib/home-banner-4.png'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import FAQ from "../faq";
import Price from "../price";
import Guides from "@/guides";

const Home = () => {

    return (
        <>
            <div className="flex flex-col pt-14 pb-5 items-center">
                <p className="text-center font-semibold text-4xl ">
                    A smart tool for extract Etsy Reviews by one click
                </p>
                <p className="flex-col md:flex-row inline-block  text-gray-600 font-light pt-3">
                    Automatically extracts reviews data from <span className="text-base font-bold text-red-600 px-1">Etsy</span>  and outputs clean CSV files. Understand the true customer experience.
                </p>

                <div className="flex flex-col gap-y-6 md:flex-row gap-x-4 items-center pt-14">
                    <a rel="noopener noreferrer" target='_blank' href='https://chromewebstore.google.com/detail/etsy-reviews-extractor/acmhfanmioofjbgnoleadjidoaomomin'>
                        <div className='flex flex-row gap-x-1 items-center text-white font-semibold cursor-pointer shadow-md text-base px-4 py-3 rounded-md border border-gray-200 bg-red-500 hover:bg-red-600'>
                            <ChromeSvg/>Free - Add to Chrome
                        </div>
                    </a>
                    {/*<a rel="noopener noreferrer" href="#">*/}
                    {/*    <div className='flex flex-row gap-x-1 items-center text-white font-semibold cursor-pointer shadow-md text-base px-7 py-3 rounded-md border border-gray-200 bg-red-500 hover:bg-red-600'>*/}
                    {/*        <EdgeSvg />Free - Add to Edge*/}
                    {/*    </div>*/}
                    {/*</a>*/}
                </div>

                <div className="pt-24 w-full mx-auto">
                    <Carousel>
                        <div>
                            <img src={banner1} alt="banner"/>
                        </div>
                        <div>
                            <img src={banner2} alt='banner'/>
                        </div>
                        <div>
                            <img src={banner3} alt='banner'/>
                        </div>
                        <div>
                            <img src={banner4} alt='banner'/>
                        </div>
                    </Carousel>
                </div>

                <Price/>
                <FAQ/>
                <Guides/>

            </div>
        </>
    )
}
export default Home;